export const YoutubeIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Social icons" clipPath="url(#clip0_1379_6469)">
      <path
        id="Exclude"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM4.33154 9.06787C4.33154 7.68716 5.45083 6.56787 6.83154 6.56787H17.1682C18.5489 6.56787 19.6682 7.68716 19.6682 9.06787V14.9326C19.6682 16.3133 18.5489 17.4326 17.1682 17.4326H6.83154C5.45083 17.4326 4.33154 16.3133 4.33154 14.9326V9.06787ZM14.6287 11.7463C14.8155 11.8639 14.8155 12.1363 14.6287 12.254L10.6337 14.7702C10.4339 14.896 10.1738 14.7524 10.1738 14.5163V9.48393C10.1738 9.24783 10.4339 9.10425 10.6337 9.23008L14.6287 11.7463Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1379_6469">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
