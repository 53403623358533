import { CustomIcon } from '../types';

const Google: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.64 12.2042C20.64 11.566 20.5827 10.9524 20.4764 10.3633H12V13.8446H16.8436C16.635 14.9696 16.0009 15.9228 15.0477 16.561V18.8192H17.9564C19.6582 17.2524 20.64 14.9451 20.64 12.2042Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 21.0009C14.4298 21.0009 16.467 20.195 17.9561 18.8205L15.0475 16.5623C14.2416 17.1023 13.2107 17.4214 11.9998 17.4214C9.65567 17.4214 7.67158 15.8382 6.96385 13.7109H3.95703V16.0428C5.43794 18.9841 8.48158 21.0009 11.9998 21.0009Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.96409 13.7108C6.78409 13.1708 6.68182 12.594 6.68182 12.0008C6.68182 11.4076 6.78409 10.8308 6.96409 10.2908V7.95898H3.95727C3.34773 9.17398 3 10.5485 3 12.0008C3 13.4531 3.34773 14.8276 3.95727 16.0426L6.96409 13.7108Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 6.57955C13.3211 6.57955 14.5075 7.03364 15.4402 7.92545L18.0216 5.34409C16.4629 3.89182 14.4257 3 11.9998 3C8.48158 3 5.43794 5.01682 3.95703 7.95818L6.96385 10.29C7.67158 8.16273 9.65567 6.57955 11.9998 6.57955Z"
      fill="#EA4335"
    />
  </svg>
);

export default Google;
