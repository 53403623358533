import { CustomIcon } from '../types';

const CHAdeMOConnector: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <g clipPath="url(#clip0_28585_57681)">
      <path
        d="M24.8236 14.8809C25.8791 8.37813 21.4633 2.25088 14.9605 1.19533C8.45769 0.139781 2.33044 4.55564 1.27489 11.0584C0.219341 17.5612 4.6352 23.6885 11.138 24.744C17.6408 25.7996 23.768 21.3837 24.8236 14.8809Z"
        fill="white"
      />
      <path
        d="M14.0839 6.61765C14.0839 5.95453 13.5462 5.41687 12.8831 5.41687C12.22 5.41687 11.6823 5.95453 11.6823 6.61765C11.6823 7.28077 12.22 7.81843 12.8831 7.81843C13.5462 7.81843 14.0839 7.28077 14.0839 6.61765Z"
        fill="#00FFC2"
      />
      <path
        d="M14.0839 19.1939C14.0839 18.5308 13.5462 17.9932 12.8831 17.9932C12.22 17.9932 11.6823 18.5308 11.6823 19.1939C11.6823 19.8571 12.22 20.3947 12.8831 20.3947C13.5462 20.3947 14.0839 19.8571 14.0839 19.1939Z"
        fill="#00FFC2"
      />
      <path
        d="M13.0495 25.9409C20.2004 25.9409 26.02 20.1213 26.02 12.9704C26.02 5.81945 20.2004 -0.00012207 13.0495 -0.00012207C5.89855 -0.00012207 0.0789795 5.81945 0.0789795 12.9704C0.0789795 20.1213 5.89855 25.9409 13.0495 25.9409ZM13.0495 2.0814C19.0534 2.0814 23.9384 6.96646 23.9384 12.9704C23.9384 18.9743 19.0534 23.8593 13.0495 23.8593C7.04556 23.8593 2.16051 18.9743 2.16051 12.9704C2.16051 6.96646 7.04556 2.0814 13.0495 2.0814Z"
        fill="#2B2B2B"
      />
      <path
        d="M17.7931 15.8332C19.4701 15.8332 20.8347 14.4685 20.8347 12.7915C20.8347 11.1145 19.4701 9.74988 17.7931 9.74988C16.1161 9.74988 14.7515 11.1145 14.7515 12.7915C14.7515 14.4685 16.1161 15.8332 17.7931 15.8332ZM17.7931 11.834C18.3205 11.834 18.7507 12.2641 18.7507 12.7915C18.7507 13.3189 18.3205 13.7491 17.7931 13.7491C17.2657 13.7491 16.8356 13.3189 16.8356 12.7915C16.8356 12.2641 17.2657 11.834 17.7931 11.834Z"
        fill="#2B2B2B"
      />
      <path
        d="M8.46089 15.8332C10.1379 15.8332 11.5025 14.4685 11.5025 12.7915C11.5025 11.1145 10.1379 9.74988 8.46089 9.74988C6.78389 9.74988 5.41925 11.1145 5.41925 12.7915C5.41925 14.4685 6.78389 15.8332 8.46089 15.8332ZM8.46089 11.834C8.98831 11.834 9.41844 12.2641 9.41844 12.7915C9.41844 13.3189 8.98831 13.7491 8.46089 13.7491C7.93347 13.7491 7.50334 13.3189 7.50334 12.7915C7.50334 12.2641 7.93347 11.834 8.46089 11.834Z"
        fill="#2B2B2B"
      />
    </g>
    <defs>
      <clipPath id="clip0_28585_57681">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CHAdeMOConnector;
