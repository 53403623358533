import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserAuthHeader } from '../../lib/schema/user/client-fetch';
import { useAuth } from '../useAuth';

export function useRecentSearchesQuery() {
  const { user } = useAuth();
  const { data: recentSearch, isLoading } = useQuery({
    queryKey: ['recentSearch', user],
    queryFn: async () => {
      if (user) {
        const authHeader = await getUserAuthHeader();
        if (authHeader) {
          const response = await fetch('/api/user/recentSearch', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authHeader,
            },
          });
          if (response.ok) {
            const savedRecentSearches: string[] = await response.json();
            return savedRecentSearches;
          }
        }
      }
      const cachedRecentSearches = sessionStorage.getItem('recentSearches');
      const result: string[] = cachedRecentSearches
        ? JSON.parse(cachedRecentSearches)
        : [];
      return result;
    },
    staleTime: Infinity,
  });
  return { recentSearch, isLoading };
}

export function useSaveRecentSearchMutation() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: string) => {
      if (input) {
        if (!user) {
          const recentSearchArray = JSON.parse(
            sessionStorage.getItem('recentSearches') || '[]'
          );
          if (!recentSearchArray.includes(input)) {
            if (recentSearchArray.length === 3) {
              recentSearchArray.shift();
            }
            recentSearchArray.push(input);
          }
          sessionStorage.setItem(
            'recentSearches',
            JSON.stringify(recentSearchArray)
          );
        } else {
          const authHeader = await getUserAuthHeader();
          if (authHeader) {
            const response = await fetch(
              `/api/user/recentSearch?search_query=${input}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: authHeader,
                },
              }
            );
            if (!response.ok) {
              throw new Error('unable to save user recent search');
            }
          }
        }
        queryClient.removeQueries({ queryKey: ['recentSearch', user] });
      }
    },
  });
}

export function useRemoveRecentSearchMutation() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: string) => {
      if (input) {
        if (!user) {
          const recentSearchArray = JSON.parse(
            sessionStorage.getItem('recentSearches') || '[]'
          );
          const latestSearchArray = recentSearchArray.filter(
            (item: string) => item !== input
          );

          sessionStorage.setItem(
            'recentSearches',
            JSON.stringify(latestSearchArray)
          );
        } else {
          const authHeader = await getUserAuthHeader();
          if (authHeader) {
            const response = await fetch(
              `/api/user/recentSearch?search_query=${input}`,
              {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: authHeader,
                },
              }
            );
            if (!response.ok) {
              throw new Error('unable to remove user recent search');
            }
          }
        }
      }
    },
    // Optimistically update the query data so UI updates immediately.
    onMutate: async (removedSearch) => {
      // Cancel any outgoing refetches for this query
      await queryClient.cancelQueries({ queryKey: ['recentSearch', user] });
      // Snapshot the previous data so we can roll back if needed.
      const previousRecentSearches = queryClient.getQueryData<string[]>([
        'recentSearch',
        user,
      ]);
      // Optimistically remove the search from the cache.
      queryClient.setQueryData<string[]>(['recentSearch', user], (old) =>
        old ? old.filter((search) => search !== removedSearch) : old
      );
      return { previousRecentSearches };
    },
    onError: (_err, _removedSearch, context) => {
      // If the mutation fails, roll back to the previous state.
      if (context?.previousRecentSearches) {
        queryClient.setQueryData(
          ['recentSearch', user],
          context.previousRecentSearches
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['recentSearch', user] });
    },
  });
}
