import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Link from '@/components/atoms/legacy/Link';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { CognitoUser } from '@/lib/schema/user/types';
import { useRouter } from 'next/compat/router';

interface Section {
  title: string;
  links: {
    name: string;
    url: string;
    analyticsEvent?: AnalyticsEvent;
  }[];
  hidden?: boolean;
}

interface MobileLinksProps {
  [key: string]: Section;
}

interface MobileHeaderLinksProps {
  user: CognitoUser | null;
  signOut: () => Promise<void>;
}

export const MobileHeaderLinks: React.FC<MobileHeaderLinksProps> = ({
  user,
  signOut,
}) => {
  const router = useRouter();
  const mobileLinks: MobileLinksProps = {
    ...(user && {
      profile: {
        title: 'My profile',
        links: [{ name: 'My EVs', url: '/my-evs' }],
      },
    }),
    search: {
      title: 'Search',
      links: [
        {
          name: 'New',
          url: '/search/condition/New',
          analyticsEvent: {
            ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
            pageTarget: '/search',
          },
        },
        {
          name: 'Used',
          url: '/search/condition/Used',
          analyticsEvent: {
            ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
            pageTarget: '/search',
          },
        },
        {
          name: 'Custom order',
          url: '/build',
          analyticsEvent: {
            ...GOOGLE_TRACK_INFO.customOrderHomePageButton,
            pageTarget: '/build',
          },
        },
        ...(process.env.NEXT_PUBLIC_ENABLE_SELL_MY_CAR === 'true'
          ? [
              {
                name: 'Sell My Car',
                url: '/sell',
              },
            ]
          : []),
      ],
    },
    discover: {
      title: 'Discover',
      links: [
        {
          name: 'Compare EVs',
          url: '/compare',
          analyticsEvent: {
            ...GOOGLE_TRACK_INFO.compareEVHomePageButton,
            pageTarget: '/compare',
          },
        },
        {
          name: 'News',
          url: '/news',
          analyticsEvent: {
            ...GOOGLE_TRACK_INFO.newsHomePageButton,
            pageTarget: '/news',
          },
        },
      ],
    },
    partner: {
      title: 'Partner with us',
      links: [
        {
          name: 'Dealer partners',
          url: '/partner',
          analyticsEvent: {
            ...GOOGLE_TRACK_INFO.partnerHomePageButton,
            pageTarget: '/partner',
          },
        },
      ],
      hidden: process.env.NEXT_PUBLIC_ENABLE_PARTNER_PAGE === 'false',
    },
  };

  return (
    <div className="flex w-full flex-col gap-xl overflow-auto px-l py-xl pt-xl m:px-[40px] l:hidden">
      {Object.keys(mobileLinks)
        .filter((sectionKey) => !mobileLinks[sectionKey].hidden)
        .map((sectionKey) => {
          const section = mobileLinks[sectionKey];
          return (
            <div key={sectionKey} className="flex flex-col gap-s">
              <h2 className="text-body1Regular text-neutral-900">
                {section.title}
              </h2>
              <ul>
                {section.links.map((link) => (
                  <li
                    key={link.name}
                    className="border-b-[1px] border-neutral-200"
                  >
                    <Link
                      className="w-full rounded-r-xsmall px-l py-m text-h4Regular text-neutral-800 focus:bg-neutral-100"
                      activeClassName="border-l-[2px] border-l-brandElectric bg-neutral-100 !text-brandMidnight"
                      href={link.url}
                      analyticsEvent={link.analyticsEvent}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      {user ? (
        <div className="w-full">
          <Button
            aria-label="Sign out"
            onClick={signOut}
            variant={ButtonVariants.Secondary}
          >
            Sign out
          </Button>
        </div>
      ) : (
        <div className="flex w-full flex-col gap-l">
          <Button
            aria-label="Create account"
            onClick={router ? () => router.push('/sign-up') : undefined}
            variant={ButtonVariants.Tertiary}
          >
            Create account
          </Button>
          <Button
            aria-label="Login"
            onClick={router ? () => router.push('/login') : undefined}
            variant={ButtonVariants.Primary}
          >
            Login
          </Button>
        </div>
      )}
    </div>
  );
};
