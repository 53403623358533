import { CustomBodyTypeIcon } from '../types';
import { getOutlineColor } from '../util';

const Luxury: CustomBodyTypeIcon = ({ className, disabled }) => {
  const outline = getOutlineColor(disabled);

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="45"
      viewBox="0 0 114 45"
      fill="none"
    >
      <path
        d="M109.744 38.998L8.21492 38.0296C7.1957 38.0296 6.27554 37.4121 5.87917 36.4718C5.87917 36.4718 0.641516 23.9249 2.02879 20.4303C2.96307 18.1006 18.9776 15.8129 21.3983 15.0972C21.4266 15.0691 21.4549 15.027 21.4832 14.9989C26.4943 9.16054 33.8979 5.86242 41.6269 5.84838C47.3459 5.84838 55.9102 5.82031 61.5442 5.82031C67.6879 5.82031 73.6616 7.86936 78.418 11.7429C81.0509 13.8761 83.5141 16.3041 85.6516 18.6619C95.7164 18.9988 102.284 18.9005 106.332 22.0022C107.734 23.0688 111.018 27.8265 111.442 29.5247C111.909 31.3492 112.66 34.4789 112.136 37.0472C111.909 38.184 110.89 38.998 109.73 38.998H109.744Z"
        fill="white"
      />
      <path
        d="M109.744 38.998L8.21492 38.0296C7.1957 38.0296 6.27554 37.4121 5.87917 36.4718C5.87917 36.4718 0.641516 23.9249 2.02879 20.4303C2.96307 18.1006 18.9776 15.8129 21.3983 15.0972C21.4266 15.0691 21.4549 15.027 21.4832 14.9989C26.4943 9.16054 33.8979 5.86242 41.6269 5.84838C47.3459 5.84838 55.9102 5.82031 61.5442 5.82031C67.6879 5.82031 73.6616 7.86936 78.418 11.7429C81.0509 13.8761 83.5141 16.3041 85.6516 18.6619C95.7164 18.9988 102.284 18.9005 106.332 22.0022C107.734 23.0688 111.018 27.8265 111.442 29.5247C111.909 31.3492 112.66 34.4789 112.136 37.0472C111.909 38.184 110.89 38.998 109.73 38.998H109.744Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M109.129 40.4016L8.62016 39.4332C7.02055 39.4332 5.60492 38.4789 4.99622 37.0052C4.09025 34.8299 -0.382993 23.7426 1.14584 19.9252C1.91025 18.0165 2.95669 16.8797 16.2632 14.6342C18.0751 14.3254 19.8021 14.0447 20.6373 13.8482C25.8891 7.88353 33.5191 4.4591 41.6587 4.44506L61.576 4.41699H61.6184C68.1018 4.41699 74.4011 6.63445 79.3557 10.6483C81.6914 12.543 84.0413 14.7745 86.3487 17.2726C87.2688 17.3007 88.1889 17.3288 89.1091 17.3569C98.5085 17.6235 102.674 17.8621 106.637 20.8795C108.223 22.0865 111.733 27.0828 112.257 29.174C112.71 30.9844 113.559 34.3667 112.965 37.314C112.597 39.0964 110.997 40.3876 109.171 40.3876L109.129 40.4016ZM3.72221 21.09C3.04273 23.3075 5.56249 31.0546 7.60093 35.9526C7.7708 36.3737 8.16717 36.6403 8.62016 36.6403L109.129 37.6087C109.624 37.6087 110.049 37.2579 110.148 36.7807C110.629 34.465 109.851 31.3633 109.468 29.8757C109.129 28.5284 106.071 24.0513 104.868 23.1251C101.64 20.655 97.5318 20.4304 88.9958 20.1778C87.8775 20.1497 86.7592 20.1076 85.6267 20.0796C85.2445 20.0796 84.8764 19.8971 84.6216 19.6164C82.2718 17.0341 79.8936 14.7605 77.5296 12.8377C73.0846 9.23084 67.4223 7.23794 61.5901 7.23794H61.5618L41.6446 7.26601C34.2411 7.26601 27.2764 10.4238 22.5767 15.9253C22.4068 16.1358 22.0812 16.3885 21.8123 16.4586C21.1045 16.6692 19.4907 16.9499 16.7162 17.413C12.4128 18.1428 5.22273 19.8269 3.72221 21.09Z"
        fill={outline}
      />
      <path
        d="M23.3145 43.0964C27.6379 43.0964 31.1427 39.6217 31.1427 35.3353C31.1427 31.049 27.6379 27.5742 23.3145 27.5742C18.9911 27.5742 15.4863 31.049 15.4863 35.3353C15.4863 39.6217 18.9911 43.0964 23.3145 43.0964Z"
        fill="#CCCCD1"
      />
      <path
        d="M23.316 44.5C18.2199 44.5 14.0723 40.3879 14.0723 35.3355C14.0723 30.283 18.2199 26.1709 23.316 26.1709C28.4121 26.1709 32.5598 30.283 32.5598 35.3355C32.5598 40.3879 28.4121 44.5 23.316 44.5ZM23.316 28.9638C19.7771 28.9638 16.9034 31.8128 16.9034 35.3214C16.9034 38.8301 19.7771 41.6791 23.316 41.6791C26.855 41.6791 29.7286 38.8301 29.7286 35.3214C29.7286 31.8128 26.855 28.9638 23.316 28.9638Z"
        fill={outline}
      />
      <path
        d="M85.9668 43.0964C90.2902 43.0964 93.795 39.6217 93.795 35.3353C93.795 31.049 90.2902 27.5742 85.9668 27.5742C81.6435 27.5742 78.1387 31.049 78.1387 35.3353C78.1387 39.6217 81.6435 43.0964 85.9668 43.0964Z"
        fill="#CCCCD1"
      />
      <path
        d="M85.9645 44.5C80.8684 44.5 76.7207 40.3879 76.7207 35.3355C76.7207 30.283 80.8684 26.1709 85.9645 26.1709C91.0606 26.1709 95.2082 30.283 95.2082 35.3355C95.2082 40.3879 91.0606 44.5 85.9645 44.5ZM85.9645 28.9638C82.4255 28.9638 79.5519 31.8128 79.5519 35.3214C79.5519 38.8301 82.4255 41.6791 85.9645 41.6791C89.5034 41.6791 92.377 38.8301 92.377 35.3214C92.377 31.8128 89.5034 28.9638 85.9645 28.9638Z"
        fill={outline}
      />
      <path
        d="M86.0506 19.9954H58.022C57.2435 19.9954 56.6064 19.3638 56.6064 18.5919C56.6064 17.82 57.2435 17.1885 58.022 17.1885H86.0506C86.8292 17.1885 87.4662 17.82 87.4662 18.5919C87.4662 19.3638 86.8292 19.9954 86.0506 19.9954Z"
        fill={outline}
      />
      <path
        d="M3.58558 27.0692L6.94055 23.743C7.47847 23.2097 7.09624 22.3115 6.34598 22.3115H2.45313C1.92937 22.3115 1.53301 22.7747 1.61794 23.2799L2.1417 26.6061C2.25495 27.2798 3.09016 27.5464 3.57145 27.0692H3.58558Z"
        fill={outline}
      />
      <path
        d="M107.588 26.2835H101.827C101.048 26.2835 100.411 25.6519 100.411 24.88C100.411 24.1081 101.048 23.4766 101.827 23.4766H107.588C108.367 23.4766 109.004 24.1081 109.004 24.88C109.004 25.6519 108.367 26.2835 107.588 26.2835Z"
        fill={outline}
      />
      <path
        d="M62.0398 24.0511H59.5767C58.7981 24.0511 58.1611 23.4195 58.1611 22.6476C58.1611 21.8757 58.7981 21.2441 59.5767 21.2441H62.0398C62.8184 21.2441 63.4554 21.8757 63.4554 22.6476C63.4554 23.4195 62.8184 24.0511 62.0398 24.0511Z"
        fill={outline}
      />
      <path
        d="M35.9656 24.052H33.5025C32.7239 24.052 32.0869 23.4205 32.0869 22.6486C32.0869 21.8767 32.7239 21.2451 33.5025 21.2451H35.9656C36.7442 21.2451 37.3812 21.8767 37.3812 22.6486C37.3812 23.4205 36.7442 24.052 35.9656 24.052Z"
        fill={outline}
      />
      <path
        d="M39.9493 13.9511C34.1196 13.1636 32.1263 14.1146 29.2639 19.3299C29.189 19.4339 29.0991 19.5231 28.9792 19.5677C28.8593 19.6271 28.7094 19.6271 28.5895 19.5825C28.4696 19.538 28.3647 19.4637 28.3048 19.3448C28.2298 19.2408 28.1999 19.1071 28.2149 18.9733C29.0241 13.1636 28.065 11.1726 22.8647 8.34943C22.7448 8.27513 22.6549 8.17112 22.6099 8.05226C22.5649 7.93339 22.5649 7.7848 22.6099 7.66593C22.6549 7.54706 22.7298 7.44305 22.8347 7.36876C22.9546 7.29447 23.0895 7.26475 23.2243 7.27961C29.0241 8.06711 31.0323 7.1013 33.9097 1.90079C33.9547 1.8265 34.0296 1.76706 34.1046 1.72249C34.1795 1.67791 34.2694 1.66305 34.3593 1.6482C34.4792 1.58876 34.6141 1.58876 34.734 1.63334C34.8689 1.67791 34.9738 1.76706 35.0337 1.88593C35.0937 1.98994 35.1237 2.12367 35.1237 2.2574C34.3144 8.0374 35.2585 10.0582 40.4738 12.8813C40.5937 12.9407 40.6687 13.0447 40.7136 13.1785C40.7586 13.2973 40.7586 13.4311 40.7136 13.5499C40.6687 13.6688 40.5937 13.7877 40.4738 13.862C40.3989 13.9065 40.309 13.9511 40.2191 13.966C40.1292 13.9808 40.0392 13.966 39.9493 13.966V13.9511Z"
        fill="white"
      />
      <path
        d="M28.7564 20.7263C28.5765 20.7263 28.3967 20.6966 28.2169 20.6371C27.8272 20.5183 27.4975 20.2359 27.3027 19.8645C27.1378 19.6267 27.0479 19.2256 27.0929 18.8392C27.8422 13.4158 27.0929 11.9003 22.3272 9.32973C21.9675 9.10685 21.7277 8.83939 21.5928 8.5125C21.428 8.03703 21.428 7.66556 21.5479 7.32382C21.6827 6.95235 21.9075 6.65518 22.2073 6.44716C22.5819 6.20942 22.9866 6.12027 23.3762 6.1797C28.7864 6.90777 30.3 6.14999 32.9226 1.38037C33.1175 1.0832 33.3123 0.904897 33.5371 0.77117C33.7019 0.682018 33.8818 0.622584 34.0616 0.578008C34.3913 0.473997 34.736 0.473997 35.0807 0.578008C35.4554 0.696877 35.7851 0.964331 35.9949 1.32094C36.1747 1.61811 36.2497 1.97472 36.2347 2.31646C35.4704 7.79929 36.2347 9.30001 41.0004 11.8854C41.3601 12.0934 41.6298 12.4055 41.7647 12.7769C41.8846 13.1484 41.8846 13.505 41.7947 13.8467C41.6598 14.2331 41.435 14.5302 41.1353 14.7531C40.8955 14.9017 40.6707 15.0057 40.4309 15.0354C40.2061 15.08 39.9813 15.0651 39.7715 15.0354C34.4063 14.3222 32.8927 15.08 30.27 19.8496C30.0302 20.2211 29.7605 20.444 29.4308 20.5926C29.221 20.6817 28.9962 20.7263 28.7714 20.7263H28.7564ZM25.4893 8.61651C28.6365 10.7116 29.7155 12.8364 29.5507 16.6699C31.7087 13.4753 33.8668 12.4203 37.8083 12.6135C34.5712 10.459 33.5221 8.28962 33.7469 4.32238C31.5739 7.6507 29.4008 8.79482 25.4893 8.61651Z"
        fill={outline}
      />
    </svg>
  );
};

export default Luxury;
