import { CustomIcon } from '../types';

const CCSConnector: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="42"
    viewBox="0 0 26 42"
    fill="none"
  >
    <g clipPath="url(#clip0_29326_8166)">
      <path
        d="M24.8024 14.8693C25.8572 8.37138 21.4446 2.24873 14.9467 1.19397C8.4488 0.139205 2.32615 4.55175 1.27138 11.0497C0.216625 17.5476 4.62917 23.6702 11.1271 24.725C17.625 25.7798 23.7477 21.3672 24.8024 14.8693Z"
        fill="white"
      />
      <path
        d="M18.8606 28.178H7.05107C3.70982 28.178 1.00305 30.8873 1.00305 34.226C1.00305 37.5647 3.71238 40.274 7.05107 40.274H18.8606C22.2018 40.274 24.9086 37.5647 24.9086 34.226C24.9086 30.8873 22.1993 28.178 18.8606 28.178Z"
        fill="white"
      />
      <path
        d="M6.93035 14.9336C6.26773 14.9336 5.73047 15.4709 5.73047 16.1335C5.73047 16.7961 6.26773 17.3334 6.93035 17.3334C7.59297 17.3334 8.13023 16.7961 8.13023 16.1335C8.13023 15.4709 7.59297 14.9336 6.93035 14.9336Z"
        fill="#00FFC2"
      />
      <path
        d="M19.4987 14.9336C18.8361 14.9336 18.2988 15.4709 18.2988 16.1335C18.2988 16.7961 18.8361 17.3334 19.4987 17.3334C20.1613 17.3334 20.6986 16.7961 20.6986 16.1335C20.6986 15.4709 20.1613 14.9336 19.4987 14.9336Z"
        fill="#00FFC2"
      />
      <path
        d="M13.0399 25.9214C20.1854 25.9214 26.0007 20.1062 26.0007 12.9607C26.0007 5.81509 20.1854 -0.00012207 13.0399 -0.00012207C5.89431 -0.00012207 0.0791016 5.81509 0.0791016 12.9607C0.0791016 20.1062 5.89431 25.9214 13.0399 25.9214ZM13.0399 2.07984C19.0393 2.07984 23.9207 6.96124 23.9207 12.9607C23.9207 18.9601 19.0393 23.8415 13.0399 23.8415C7.04046 23.8415 2.15907 18.9601 2.15907 12.9607C2.15907 6.96124 7.04046 2.07984 13.0399 2.07984Z"
        fill="#2B2B2B"
      />
      <path
        d="M17.7024 12.1089C19.3782 12.1089 20.7418 10.7453 20.7418 9.06951C20.7418 7.39377 19.3782 6.03015 17.7024 6.03015C16.0267 6.03015 14.6631 7.39377 14.6631 9.06951C14.6631 10.7453 16.0267 12.1089 17.7024 12.1089ZM17.7024 8.11268C18.2295 8.11268 18.6593 8.54248 18.6593 9.06951C18.6593 9.59654 18.2295 10.0263 17.7024 10.0263C17.1754 10.0263 16.7456 9.59654 16.7456 9.06951C16.7456 8.54248 17.1754 8.11268 17.7024 8.11268Z"
        fill="#2B2B2B"
      />
      <path
        d="M10.1221 19.2441C10.1221 20.9198 11.4857 22.2834 13.1614 22.2834C14.8372 22.2834 16.2008 20.9198 16.2008 19.2441C16.2008 17.5683 14.8372 16.2047 13.1614 16.2047C11.4857 16.2047 10.1221 17.5683 10.1221 19.2441ZM13.1614 18.2872C13.6885 18.2872 14.1183 18.717 14.1183 19.2441C14.1183 19.7711 13.6885 20.2009 13.1614 20.2009C12.6344 20.2009 12.2046 19.7711 12.2046 19.2441C12.2046 18.717 12.6344 18.2872 13.1614 18.2872Z"
        fill="#2B2B2B"
      />
      <path
        d="M8.37627 12.0679C10.052 12.0679 11.4156 10.7042 11.4156 9.0285C11.4156 7.35275 10.052 5.98914 8.37627 5.98914C6.70053 5.98914 5.33691 7.35275 5.33691 9.0285C5.33691 10.7042 6.70053 12.0679 8.37627 12.0679ZM8.37627 8.07166C8.9033 8.07166 9.33311 8.50147 9.33311 9.0285C9.33311 9.55552 8.9033 9.98533 8.37627 9.98533C7.84925 9.98533 7.41944 9.55552 7.41944 9.0285C7.41944 8.50147 7.84925 8.07166 8.37627 8.07166Z"
        fill="#2B2B2B"
      />
      <path
        d="M18.8624 27.1749H7.05286C3.16412 27.1749 0.00195312 30.3371 0.00195312 34.2258C0.00195312 38.1146 3.16412 41.2767 7.05286 41.2767H18.8624C22.7511 41.2767 25.9133 38.1146 25.9133 34.2258C25.9133 30.3371 22.7511 27.1749 18.8624 27.1749ZM18.8624 39.2735H7.05286C4.26934 39.2735 2.00773 37.0094 2.00773 34.2284C2.00773 31.4474 4.2719 29.1807 7.05286 29.1807H18.8624C21.6459 29.1807 23.9101 31.4449 23.9101 34.2284C23.9101 37.0119 21.6459 39.2735 18.8624 39.2735Z"
        fill="#2B2B2B"
      />
      <path
        d="M7.29391 30.3397C5.11672 30.3397 3.34888 32.1101 3.34888 34.2847C3.34888 36.4594 5.11928 38.2298 7.29391 38.2298C9.46853 38.2298 11.2389 36.4594 11.2389 34.2847C11.2389 32.1101 9.46853 30.3397 7.29391 30.3397ZM7.29391 36.2266C6.2245 36.2266 5.35209 35.3567 5.35209 34.2847C5.35209 33.2128 6.22194 32.3429 7.29391 32.3429C8.36587 32.3429 9.23572 33.2128 9.23572 34.2847C9.23572 35.3567 8.36587 36.2266 7.29391 36.2266Z"
        fill="#2B2B2B"
      />
      <path
        d="M18.3835 30.3397C16.2089 30.3397 14.4385 32.1101 14.4385 34.2847C14.4385 36.4594 16.2089 38.2298 18.3835 38.2298C20.5581 38.2298 22.3285 36.4594 22.3285 34.2847C22.3285 32.1101 20.5581 30.3397 18.3835 30.3397ZM18.3835 36.2266C17.3141 36.2266 16.4417 35.3567 16.4417 34.2847C16.4417 33.2128 17.3115 32.3429 18.3835 32.3429C19.4555 32.3429 20.3253 33.2128 20.3253 34.2847C20.3253 35.3567 19.4555 36.2266 18.3835 36.2266Z"
        fill="#2B2B2B"
      />
    </g>
    <defs>
      <clipPath id="clip0_29326_8166">
        <rect width="25.9983" height="41.277" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CCSConnector;
