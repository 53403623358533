import { CustomIcon } from './types';

const ChargingLock: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.75C7 4.85051 9.35051 2.5 12.25 2.5C15.1495 2.5 17.5 4.85051 17.5 7.75V10.75C19.1569 10.75 20.5 12.0931 20.5 13.75V20.5C20.5 22.1569 19.1569 23.5 17.5 23.5H7C5.34315 23.5 4 22.1569 4 20.5V13.75C4 12.0931 5.34315 10.75 7 10.75V7.75ZM16 7.75V10.75H8.5V7.75C8.5 5.67893 10.1789 4 12.25 4C14.3211 4 16 5.67893 16 7.75ZM13.4909 13.1857C13.5326 12.9771 13.4286 12.7665 13.2376 12.6728C13.0465 12.5792 12.8164 12.626 12.677 12.7868L8.61453 17.4743C8.49433 17.6129 8.46613 17.809 8.54236 17.976C8.61859 18.1429 8.78523 18.25 8.96876 18.25H11.522L11.0091 20.8143C10.9674 21.023 11.0714 21.2335 11.2624 21.3272C11.4535 21.4208 11.6836 21.374 11.823 21.2133L15.8855 16.5258C16.0057 16.3871 16.0339 16.191 15.9577 16.024C15.8814 15.8571 15.7148 15.75 15.5313 15.75H12.978L13.4909 13.1857Z"
      fill="#010044"
    />
  </svg>
);
export default ChargingLock;
