import { CustomIcon } from './types';

const CarToRight: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:car" clipPath="url(#clip0_3762_7796)">
      <path
        id="Vector"
        d="M12.9163 11.3337H14.2497C14.6497 11.3337 14.9163 11.067 14.9163 10.667V8.66699C14.9163 8.06699 14.4497 7.53366 13.9163 7.40033C12.7163 7.06699 10.9163 6.66699 10.9163 6.66699C10.9163 6.66699 10.0497 5.73366 9.44967 5.13366C9.11634 4.86699 8.71634 4.66699 8.24967 4.66699H3.58301C3.18301 4.66699 2.84967 4.93366 2.64967 5.26699L1.71634 7.20033C1.62806 7.45781 1.58301 7.72813 1.58301 8.00033V10.667C1.58301 11.067 1.84967 11.3337 2.24967 11.3337H3.58301"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M4.91634 12.6667C5.65272 12.6667 6.24967 12.0697 6.24967 11.3333C6.24967 10.597 5.65272 10 4.91634 10C4.17996 10 3.58301 10.597 3.58301 11.3333C3.58301 12.0697 4.17996 12.6667 4.91634 12.6667Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M6.25 11.333H10.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M11.5833 12.6667C12.3197 12.6667 12.9167 12.0697 12.9167 11.3333C12.9167 10.597 12.3197 10 11.5833 10C10.847 10 10.25 10.597 10.25 11.3333C10.25 12.0697 10.847 12.6667 11.5833 12.6667Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3762_7796">
        <rect width="16" height="16" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
);

export default CarToRight;
