import Wrapper from '@/components/atoms/legacy/Wrapper';
import { Footer } from '@/components/organisms/Footer';
import FunnelFooter from '@/components/organisms/FunnelFooter/FunnelFooter';
import clsx from 'clsx';
import { useRouter } from 'next/compat/router';
import { usePathname } from 'next/navigation';
import React from 'react';

export const FooterTemplate: React.FC<{ hideOnPrint: boolean }> = ({
  hideOnPrint,
}) => {
  const pageRouter = useRouter();
  const appRouterPathname = usePathname();
  const showFunnelFooter =
    (!!pageRouter?.pathname || !!appRouterPathname) &&
    (pageRouter?.pathname.includes('/[...slug]') ||
      pageRouter?.pathname.includes('/settings') ||
      pageRouter?.pathname.includes('/dealer') ||
      appRouterPathname?.includes('/sell/forms'));

  return (
    <Wrapper
      className={clsx(
        'relative bottom-0 w-full bg-[linear-gradient(339.57deg,_#020222_59.68%,_#010044_87.45%,_#3a8e7c_100%)]',
        hideOnPrint ? 'print:hidden' : ''
      )}
    >
      {showFunnelFooter ? <FunnelFooter /> : <Footer id="main-footer" />}
    </Wrapper>
  );
};
