import { CustomIcon } from './types';

const Triangle: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
  >
    <path
      d="M6.03923 7.96929C5.57735 8.76929 4.42265 8.76929 3.96077 7.96929L0.843079 2.56929C0.381198 1.76929 0.958549 0.769287 1.88231 0.769287L8.11769 0.769287C9.04145 0.769287 9.6188 1.76929 9.15692 2.56929L6.03923 7.96929Z"
      fill="#010044"
    />
  </svg>
);
export default Triangle;
