import { ScComponent } from '@/types/interfaces';
import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface CheckboxProps extends ScComponent {
  disabled?: boolean;
  checked?: boolean;
  onChange: (val: boolean) => void;
  name?: string;
  checkBoxVariant?: CheckboxVariant;
}

enum CheckboxVariant {
  'Default' = 'bg-brandMidnight',
  'Turquoise' = 'bg-brandElectric',
}

const Checkbox: React.FC<CheckboxProps> = ({
  className = '',
  checked = false,
  onChange,
  checkBoxVariant = CheckboxVariant.Default,
  ...props
}) => {
  return (
    <section className="flex cursor-pointer items-center">
      <input
        className="hidden"
        type="checkbox"
        checked={checked}
        onChange={(evt) => onChange(evt.currentTarget.checked)}
        {...props}
      />
      <div
        className={`flex h-5 w-5 items-center justify-center rounded-xsmall border-2 ${className} ${
          checked
            ? `border-transparent ${checkBoxVariant}`
            : 'border-gray-300 bg-white'
        }`}
      >
        <CheckIcon stroke="white" width={26} height={26} />
      </div>
    </section>
  );
};

export default Checkbox;
