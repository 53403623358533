import { Component } from '@/types/interfaces';
import React from 'react';

export enum TextButtonVariants {
  Primary = 'text-button-primary',
  Secondary = 'text-button-secondary',
  Tertiary = 'text-button-tertiary',
  Default = 'text-button-default',
}

export enum TextButtonSizes {
  Normal = 'text-button-normal',
  Small = 'text-button-small',
}

export interface TextButtonProps
  extends Component,
    Pick<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'disabled' | 'type'
    > {
  size?: TextButtonSizes;
  variant?: TextButtonVariants;
  children: React.ReactNode;
  ariaLabel?: string;
  textClassName?: string;
}

const TextButton: React.FC<TextButtonProps> = ({
  className = '',
  size = TextButtonSizes.Normal,
  variant = TextButtonVariants.Default,
  children,
  ariaLabel = '',
  textClassName = '',
  ...props
}) => {
  return (
    <button
      className={`text-button group ${variant} ${size} ${className}`}
      aria-label={ariaLabel}
      {...props}
    >
      <span
        className={`group-hover:text-blue-dark group-hover:underline ${textClassName}`}
      >
        {children}
      </span>
    </button>
  );
};

export default TextButton;
