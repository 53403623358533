import { CustomIcon } from './types';

const EVMono: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="61"
    height="12"
    viewBox="0 0 61 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_23201_1849)">
      <path
        d="M28.9527 11.8002L34.0735 -0.0222317V-0.0232239H30.5201C30.5201 -0.0232239 27.6824 7.23829 27.585 7.54288V7.54685C27.5363 7.39207 24.616 -0.0222317 24.616 -0.0222317H20.9712L26.0553 11.8002H28.9527Z"
        fill="#87878B"
      />
      <path
        d="M19.0181 4.62201H14.4568V2.73098H20.2317L19.0479 -0.0222256H10.9949V11.8002H24.132L22.9472 9.04698L22.9482 9.04598H14.4568V7.22242H20.1373L19.0181 4.62201Z"
        fill="#87878B"
      />
      <path
        d="M39.1924 11.8964C38.5731 11.8964 38.0036 11.8041 37.4838 11.6196C36.9639 11.4351 36.5137 11.1771 36.135 10.8457C35.7553 10.5143 35.4621 10.1195 35.2553 9.6621C35.0486 9.20472 34.9452 8.7017 34.9452 8.15503C34.9452 7.60836 35.0506 7.10633 35.2603 6.64796C35.47 6.19058 35.7672 5.79571 36.1509 5.46433C36.5345 5.13295 36.9898 4.87499 37.5166 4.69045C38.0434 4.50592 38.6238 4.41365 39.258 4.41365C39.6188 4.41365 39.9746 4.45333 40.3255 4.53171C40.6754 4.61009 41.0073 4.73014 41.3204 4.89087C41.6335 5.0516 41.9198 5.25499 42.1772 5.50104L40.7509 7.04879C40.6475 6.9327 40.5243 6.82654 40.3802 6.7313C40.236 6.63605 40.07 6.55867 39.8822 6.50112C39.6943 6.44358 39.4786 6.41381 39.2351 6.41381C38.9916 6.41381 38.7471 6.4535 38.5215 6.53188C38.2968 6.61026 38.101 6.72535 37.935 6.87516C37.769 7.02597 37.6378 7.21051 37.5424 7.42878C37.446 7.64705 37.3983 7.90005 37.3983 8.18678C37.3983 8.44672 37.446 8.68186 37.5424 8.89418C37.6378 9.1065 37.775 9.28707 37.9519 9.43787C38.1288 9.58868 38.3425 9.70476 38.593 9.78612C38.8435 9.86846 39.1238 9.90914 39.4339 9.90914C39.6556 9.90914 39.8653 9.87838 40.0641 9.81687C40.2629 9.75536 40.4438 9.67499 40.6058 9.57578C40.7678 9.47657 40.9079 9.36545 41.0262 9.24242L42.1653 10.9033C41.9735 11.0948 41.719 11.2654 41.4019 11.4162C41.0849 11.567 40.735 11.6841 40.3513 11.7694C39.9677 11.8547 39.581 11.8974 39.1904 11.8974L39.1924 11.8964Z"
        fill="#87878B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.3191 11.6196C45.828 11.8042 46.3876 11.8964 46.9999 11.8964V11.8974C47.6052 11.8974 48.1598 11.8051 48.6648 11.6206C49.1697 11.4361 49.611 11.1771 49.9867 10.8418C50.3624 10.5074 50.6517 10.1106 50.8545 9.65318C51.0572 9.19481 51.1586 8.68584 51.1586 8.14611C51.1586 7.60638 51.0572 7.10932 50.8545 6.65492C50.6517 6.20051 50.3624 5.80663 49.9867 5.47129C49.61 5.13693 49.1697 4.87699 48.6648 4.69245C48.1588 4.50791 47.5933 4.41564 46.989 4.41564C46.3846 4.41564 45.828 4.50791 45.3191 4.69245C44.8102 4.87699 44.3679 5.13693 43.9922 5.47129C43.6165 5.80564 43.3272 6.20051 43.1245 6.65492C42.9217 7.10932 42.8203 7.6163 42.8203 8.15603C42.8203 8.69576 42.9217 9.19481 43.1245 9.65219C43.3262 10.1096 43.6165 10.5064 43.9922 10.8408C44.3679 11.1751 44.8102 11.4351 45.3191 11.6196ZM47.6798 9.72362C47.47 9.80597 47.2394 9.84665 46.989 9.84665C46.7385 9.84665 46.5079 9.80597 46.2982 9.72362C46.0884 9.64227 45.9036 9.5242 45.7455 9.37042C45.5875 9.21663 45.4652 9.03805 45.3807 8.83267C45.2963 8.6273 45.2535 8.40208 45.2535 8.15603C45.2535 7.90998 45.2963 7.68476 45.3807 7.47939C45.4652 7.27501 45.5865 7.09543 45.7455 6.94165C45.9036 6.78786 46.0884 6.67079 46.2982 6.58844C46.5079 6.50609 46.7385 6.46542 46.989 6.46542C47.2394 6.46542 47.47 6.50609 47.6798 6.58844C47.8895 6.6698 48.0734 6.78786 48.2324 6.94165C48.3904 7.09543 48.5127 7.27401 48.5972 7.47939C48.6817 7.68476 48.7244 7.90998 48.7244 8.15603C48.7244 8.40208 48.6817 8.6273 48.5972 8.83267C48.5127 9.03706 48.3904 9.21663 48.2324 9.37042C48.0744 9.5242 47.8895 9.64127 47.6798 9.72362Z"
        fill="#87878B"
      />
      <path
        d="M52.3633 11.7942V4.62003H54.4208L56.4083 7.61797L58.4244 4.62003H60.4153V11.7942H58.1153V10.0828C58.1153 9.46765 58.1322 8.91403 58.165 8.42193C58.1698 8.35008 58.1751 8.27832 58.181 8.20664L56.7327 10.2882H56.0031L54.6066 8.24514C54.6098 8.28514 54.6128 8.32522 54.6156 8.36538C54.6484 8.84756 54.6653 9.41903 54.6653 10.0818V11.7932H52.3653L52.3633 11.7942Z"
        fill="#87878B"
      />
      <path
        d="M33.8399 10.7128C33.8399 11.4114 33.2725 11.9778 32.5726 11.9778C31.8727 11.9778 31.3053 11.4114 31.3053 10.7128C31.3053 10.0142 31.8727 9.4478 32.5726 9.4478C33.2725 9.4478 33.8399 10.0142 33.8399 10.7128Z"
        fill="#87878B"
      />
      <path
        d="M9.09241 4.60415H4.72994L4.73094 0.933216C4.73094 0.661368 4.45561 0.663352 4.39299 0.793323L1.0205 6.91585C0.942972 7.0488 0.998634 7.25417 1.16065 7.25417H5.30842L5.30743 10.9886C5.30743 11.2426 5.56387 11.2585 5.65531 11.1186L9.2415 4.93255C9.324 4.77183 9.25542 4.60515 9.09142 4.60515L9.09241 4.60415Z"
        fill="#87878B"
      />
    </g>
    <defs>
      <clipPath id="clip0_23201_1849">
        <rect
          width="59.4667"
          height="12"
          fill="white"
          transform="translate(0.966675)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EVMono;
