import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { Component } from '@/types/interfaces';
import { useRouter } from 'next/compat/router';
import NextLink, { LinkProps as NextLinkProp } from 'next/link';
import React, { HTMLAttributeAnchorTarget } from 'react';

/** @deprecated */
export enum LinkVariants {
  Primary = 'link-primary',
  Secondary = 'link-secondary',
  Tertiary = 'link-tertiary',
  Default = 'link-default',
}

/** @deprecated */
export enum LinkSizes {
  Normal = 'link-normal',
  Small = 'link-small',
}

/** @deprecated */
export interface LinkProps extends Component, NextLinkProp {
  activeClassName?: string;
  size?: LinkSizes;
  variant?: LinkVariants;
  activeIconClass?: string;
  key?: string | number;
  childrenStyle?: string;
  directionIconColor?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  onClick?: (evt?: React.MouseEvent) => void;
  analyticsEvent?: AnalyticsEvent;
}

/** @deprecated
 * Use `src/components/atoms/Link` instead.
 * This component is a legacy version of the `Link` component, that has too many "default styles", which makes it hard to re-use this component.
 */
const Link: React.FC<LinkProps> = ({
  className = 'w-full h-full',
  activeClassName = '',
  size = LinkSizes.Normal,
  variant = LinkVariants.Default,
  children,
  childrenStyle = 'w-full h-full',
  directionIconColor,
  onClick = () => {},
  analyticsEvent,
  ...props
}) => {
  const router = useRouter();
  const [computedClassName, setComputedClassName] = React.useState(className);

  React.useEffect(() => {
    // Check if the router fields are updated client-side
    if (router?.isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(
        (props.as || props.href) as string,
        window.location.href
      ).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(router.asPath, window.location.href)
        .pathname;

      const newClassName =
        linkPathname === activePathname
          ? `${className} ${activeClassName}`.trim()
          : className;

      if (newClassName !== computedClassName) {
        setComputedClassName(newClassName);
      }
    }
  }, [
    router,
    props.as,
    props.href,
    activeClassName,
    className,
    computedClassName,
  ]);
  const { handleActionTracking } = useAnalytics();

  return (
    <NextLink
      className={`link group flex justify-center ${size} ${variant} ${computedClassName}`}
      onClick={() => {
        if (analyticsEvent) {
          handleActionTracking(analyticsEvent);
        }
        onClick();
      }}
      {...props}
      prefetch={false}
    >
      <div className={childrenStyle}>{children}</div>
    </NextLink>
  );
};

export default Link;
