import { CustomIcon } from '../types';

const Level1Charger: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <g id="Group">
        <path
          id="Vector"
          d="M16.6663 21.9233V35M22.2213 10.6417V5M11.1113 10.6417V5M24.9997 13.975V18.59C24.9997 19.4741 24.6485 20.3219 24.0234 20.947C23.3982 21.5721 22.5504 21.9233 21.6663 21.9233H11.6663C10.7823 21.9233 9.93444 21.5721 9.30932 20.947C8.6842 20.3219 8.33301 19.4741 8.33301 18.59V13.9733C8.33301 13.0893 8.6842 12.2414 9.30932 11.6163C9.93444 10.9912 10.7823 10.64 11.6663 10.64H21.6663C22.5504 10.64 23.3982 10.9912 24.0234 11.6163C24.6485 12.2414 24.9997 13.0893 24.9997 13.9733V13.975Z"
          stroke="#38393A"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
      </g>
      <path
        id="Vector_2"
        d="M32.3513 28.0011L28.7253 27.9613L28.7883 22L23 30.6015L26.6259 30.6414L26.569 36.0231L32.3513 28.0011Z"
        fill="#0EDAB5"
      />
    </g>
  </svg>
);
export default Level1Charger;
