import { CustomIcon } from '../types';

const BatteryChargerMedium: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M13.0003 21.6639H4.33366V6.49727H13.0003M13.7262 4.33061H11.917V2.16394H5.41699V4.33061H3.60783C2.81699 4.33061 2.16699 4.98061 2.16699 5.77144V22.3898C2.16699 23.1806 2.81699 23.8306 3.60783 23.8306H13.7262C14.5278 23.8306 15.167 23.1914 15.167 22.3898V5.77144C15.167 4.98061 14.517 4.33061 13.7262 4.33061ZM11.917 17.3306H5.41699V20.5806H11.917V17.3306ZM11.917 12.4556H5.41699V15.7056H11.917V12.4556ZM24.917 10.8306H21.667V3.24727L16.2503 14.0806H19.5003V22.7473"
      fill="#38393A"
    />
    <path
      d="M5.28125 20.9628V17.3065H12.1875V20.9628H5.28125Z"
      fill="#996D15"
    />
    <path
      d="M5.28125 16.0878V12.4315H12.1875V16.0878H5.28125Z"
      fill="#996D15"
    />
  </svg>
);
export default BatteryChargerMedium;
