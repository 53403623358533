import stringSimilarity from 'string-similarity';

export const stringMatch = (
  newInput: string,
  makesList: string[],
  modelsList: { [key: string]: string[] }
) => {
  const inputMake = newInput.split(' ');

  // Get model-to-make mapping
  const modelKeys = Object.keys(modelsList).reduce(
    (acc, make) => {
      modelsList[make].forEach((model) => {
        acc[model] = make;
      });
      return acc;
    },
    {} as { [key: string]: string }
  );

  // flat list of models
  const modelListFlat = Object.values(modelsList).flat();

  // string match all makes and models
  const stringMatchAllWithScores = [...makesList, ...modelListFlat].reduce(
    (acc, item) => {
      if (item) {
        const score = stringSimilarity.compareTwoStrings(
          newInput.toLowerCase(),
          item.toLowerCase()
        );
        if (score > 0) {
          acc[item] = score;
        }
      }
      return acc;
    },
    {} as { [key: string]: number }
  );

  // sort by score and take top 10
  const orderedStringMatches = Object.keys(stringMatchAllWithScores)
    .sort((a, b) => stringMatchAllWithScores[b] - stringMatchAllWithScores[a])
    .slice(0, 10);

  // if no matches, return empty array
  if (orderedStringMatches.length === 0) {
    return [];
  }

  let sortedOptions: string[] = [];
  let prefix = '';

  // sort the matches alphabetically depending on the input
  for (let i = 0; i < inputMake.length; i += 1) {
    if (inputMake[i] !== '') {
      sortedOptions = orderedStringMatches.filter((item) =>
        item.toLowerCase().startsWith(inputMake[i])
      );

      if (sortedOptions.length > 0) {
        sortedOptions.sort((a, b) => {
          const aLower = a.toLowerCase();
          const bLower = b.toLowerCase();
          return aLower.localeCompare(bLower);
        });
        prefix = inputMake.slice(0, i).join(' ');
        break;
      }
    }
  }

  if (sortedOptions.length === 0) {
    return [];
  }
  // get top match and make
  const topMatch = sortedOptions[0];
  const topMake =
    modelKeys[topMatch] || makesList.find((make) => make === topMatch);

  // refined matches are based on the make of the top match
  // first item is the top match
  // next items are items in orderedStringMatches that have the same make as the top match
  // if top make is not in the list, add it
  // if there are less than 5 items, add more models from the top make
  const refinedMatches = [
    topMatch,
    ...orderedStringMatches.filter(
      (match, index) => index > 0 && modelKeys[match] === topMake
    ),
  ];

  if (topMake) {
    if (topMatch !== topMake) {
      refinedMatches.push(topMake);
    }
    const makeModels = modelsList[topMake].filter(
      (model) => !refinedMatches.includes(model)
    );
    refinedMatches.push(...makeModels);
  }

  // build final menu items
  const finalMenuItems = refinedMatches.map((item) => {
    const isMake = makesList.includes(item);
    const makeIcon = isMake ? { make: item } : undefined;
    const inputText = isMake ? item : `${modelKeys[item]} ${item}`;
    const fullText = prefix && !makeIcon ? `${prefix} ${inputText}` : inputText;

    return {
      text: fullText,
      value: fullText,
      makeIcon,
    };
  });

  return finalMenuItems;
};
