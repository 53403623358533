import * as BodyTypeIcons from './body-types';
import { CustomBodyTypeIconProps } from './types';

export type BodyTypeIconId = keyof typeof BodyTypeIcons;

interface BodyTypeIconProps extends CustomBodyTypeIconProps {
  bodyType: BodyTypeIconId;
}

export const BodyTypeIcon: React.FC<BodyTypeIconProps> = ({
  bodyType,
  ...props
}) => {
  const Icon = BodyTypeIcons[bodyType];

  return <Icon {...props} />;
};
