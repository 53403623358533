import { CustomIcon } from './types';

const DownloadReport: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="96"
    height="88"
    viewBox="0 0 96 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.82715 7.5C2.82715 4.37897 5.22733 2 8.00572 2H40.9251C42.2696 2 43.5777 2.55719 44.5563 3.57961L64.2798 24.1862C65.2612 25.2116 65.8271 26.6203 65.8271 28.1066V80.5C65.8271 83.621 63.427 86 60.6486 86H8.00572C5.22733 86 2.82715 83.621 2.82715 80.5V7.5Z"
      fill="white"
      stroke="#010044"
      strokeWidth="4"
    />
    <path
      d="M12.1025 36.875H50.1836"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1025 48.875H53.1836"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1025 60.875H53.1836"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="67.2617" cy="44" r="26.1333" fill="#0EDAB5" />
    <circle cx="67.2617" cy="44" r="26.1333" fill="white" fillOpacity="0.2" />
    <circle
      cx="67.2617"
      cy="44"
      r="26.1333"
      stroke="#010044"
      strokeWidth="3.73333"
    />
    <path
      d="M58.6006 46.4375L67.2617 55.0996"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.9229 46.4375L67.2617 55.0996"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.2539 55.0977V32.9023"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1025 24.875H34.1025"
      stroke="#010044"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadReport;
