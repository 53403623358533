import { CustomIcon } from '../types';

const NAConnector: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="86"
    viewBox="0 0 85 86"
    fill="none"
  >
    <g clipPath="url(#clip0_29756_123508)">
      <path
        d="M78.6153 57.6343C86.8607 37.7283 77.4078 14.9071 57.5018 6.6617C37.5957 -1.58366 14.7745 7.86921 6.52915 27.7753C-1.71621 47.6813 7.73665 70.5025 27.6427 78.7479C47.5488 86.9933 70.37 77.5404 78.6153 57.6343Z"
        fill="#D5D7D9"
      />
      <path
        d="M42.5848 0.281738C19.198 0.281738 0.166016 19.3137 0.166016 42.7005C0.166016 66.0873 19.198 85.1193 42.5848 85.1193C65.9716 85.1193 85.0036 66.0873 85.0036 42.7005C85.0036 19.3137 65.9811 0.281738 42.5848 0.281738ZM42.5848 78.3168C22.9456 78.3168 6.97807 62.3397 6.97807 42.71C6.97807 23.0803 22.9551 7.09379 42.5848 7.09379C62.2145 7.09379 78.1916 23.0708 78.1916 42.71C78.1916 62.3492 62.2145 78.3168 42.5848 78.3168Z"
        fill="#CCCCD1"
      />
    </g>
    <defs>
      <clipPath id="clip0_29756_123508">
        <rect
          width="84.8471"
          height="84.8471"
          fill="white"
          transform="translate(0.160156 0.281738)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default NAConnector;
