import DropdownMenuItem, {
  DropdownMenuItemVariants,
} from '@/components/molecules/DropdownMenuItem';
import {
  useRecentSearchesQuery,
  useRemoveRecentSearchMutation,
} from '@/hooks/queries/recent-searches';
import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';

interface RecentSearchListProps {
  onChange: ([searchOption]: [string]) => void;
}

export const RecentSearchList = ({ onChange }: RecentSearchListProps) => {
  const { mutate: removeRecentSearch } = useRemoveRecentSearchMutation();
  const { recentSearch: recentSearches, isLoading } = useRecentSearchesQuery();

  if (isLoading) {
    return (
      <div className="flex flex-col gap-s px-[14px] py-[10px]">
        {[1, 2, 3].map((_, index) => (
          <div
            className="flex flex-row justify-between"
            key={`recent-search-dropdown-skeleton-${index}`}
          >
            <div className="flex gap-s">
              <Skeleton className="size-l" />
              <Skeleton className="h-[22px] w-[42px]" />
            </div>
            <Skeleton className="size-l" />
          </div>
        ))}
      </div>
    );
  }

  return !!recentSearches && recentSearches.length > 0 ? (
    <div className="flex flex-col gap-s">
      {recentSearches.map((recent, index) => {
        const isLast = index === recentSearches.length - 1;
        return (
          <div
            key={`recent-search-dropdown-${index}`}
            className={clsx(
              'flex h-full items-center gap-s border-solid border-neutral-200 ml:border-b-0',
              isLast ? 'border-b-0' : 'border-b'
            )}
          >
            <DropdownMenuItem
              text={recent}
              variant={DropdownMenuItemVariants.NoSelect}
              showCount
              onChange={() => {
                onChange([recent]);
              }}
              selected={false}
              iconNode={
                <ClockIcon className="mr-s flex h-l w-l text-neutral-600" />
              }
            />
            <div
              className="cursor-pointer px-s"
              onMouseDown={(e) => {
                e.preventDefault();
                removeRecentSearch(recent);
              }}
            >
              <XMarkIcon className="size-l text-neutral-600" />
            </div>
          </div>
        );
      })}
      <div className="hidden h-full border-[1px] border-solid border-neutral-200 ml:block"></div>
    </div>
  ) : null;
};
