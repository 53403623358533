import { CustomIcon } from './types';

const EVChargerPin: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="28"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_29560_43368)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5537 23.5609C11.583 23.5779 11.606 23.591 11.6223 23.6001L11.6494 23.6152C11.8655 23.7339 12.1336 23.733 12.3499 23.6156L12.3777 23.6001C12.394 23.591 12.417 23.5779 12.4463 23.5609C12.5049 23.527 12.5886 23.4775 12.6938 23.4127C12.9042 23.2831 13.2012 23.0921 13.556 22.8414C14.2643 22.3411 15.2095 21.5986 16.1566 20.6288C18.0419 18.6986 20 15.7986 20 12.069C20 7.65069 16.4183 4.06897 12 4.06897C7.58172 4.06897 4 7.65069 4 12.069C4 15.7986 5.95813 18.6986 7.84335 20.6288C8.79055 21.5986 9.73567 22.3411 10.444 22.8414C10.7988 23.0921 11.0958 23.2831 11.3062 23.4127C11.4114 23.4775 11.4951 23.527 11.5537 23.5609Z"
        fill="white"
      />
    </g>
    <rect
      x="4.99304"
      y="4.82568"
      width="13.9024"
      height="13.9024"
      rx="6.9512"
      fill="#EA4336"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9903 7.19352C13.1927 7.29271 13.3029 7.51575 13.2587 7.73673L12.7154 10.4529H15.4199C15.6143 10.4529 15.7908 10.5664 15.8715 10.7432C15.9523 10.92 15.9224 11.1277 15.7951 11.2746L11.492 16.2398C11.3444 16.4101 11.1006 16.4596 10.8982 16.3604C10.6959 16.2612 10.5857 16.0382 10.6299 15.8172L11.1731 13.101H8.46869C8.27429 13.101 8.09778 12.9876 8.01703 12.8107C7.93629 12.6339 7.96616 12.4262 8.09348 12.2793L12.3966 7.31418C12.5442 7.14387 12.788 7.09434 12.9903 7.19352Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_29560_43368"
        x="0.0278876"
        y="0.0968573"
        width="23.9442"
        height="27.5792"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.98606" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_29560_43368"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_29560_43368"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default EVChargerPin;
