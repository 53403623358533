import { CustomIcon } from '../types';

const ElectricityBarWhite: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="55"
    viewBox="0 0 49 55"
    fill="none"
  >
    <path
      d="M1.80801 31.7659L3.17558 31.1497L1.80801 31.7659C2.3769 33.0284 3.63573 33.8333 5.01566 33.8333H20.8201L17.7036 48.2642C17.3667 49.8242 18.1318 51.4055 19.5511 52.1217C20.9673 52.8364 22.6926 52.5182 23.7567 51.338L46.6004 26.0048C47.528 24.9761 47.7617 23.4984 47.192 22.2341C46.6231 20.9716 45.3643 20.1667 43.9843 20.1667H28.1799L31.2964 5.73578L29.8302 5.41914L31.2964 5.73577C31.6333 4.17584 30.8682 2.59451 29.4489 1.87826C28.0327 1.16356 26.3074 1.48183 25.2433 2.66198L2.39955 27.9952C1.472 29.0239 1.23831 30.5016 1.80801 31.7659Z"
      fill="#74E437"
    />
    <path
      d="M1.80801 31.7659L3.17558 31.1497L1.80801 31.7659C2.3769 33.0284 3.63573 33.8333 5.01566 33.8333H20.8201L17.7036 48.2642C17.3667 49.8242 18.1318 51.4055 19.5511 52.1217C20.9673 52.8364 22.6926 52.5182 23.7567 51.338L46.6004 26.0048C47.528 24.9761 47.7617 23.4984 47.192 22.2341C46.6231 20.9716 45.3643 20.1667 43.9843 20.1667H28.1799L31.2964 5.73578L29.8302 5.41914L31.2964 5.73577C31.6333 4.17584 30.8682 2.59451 29.4489 1.87826C28.0327 1.16356 26.3074 1.48183 25.2433 2.66198L2.39955 27.9952C1.472 29.0239 1.23831 30.5016 1.80801 31.7659Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M1.80801 31.7659L3.17558 31.1497L1.80801 31.7659C2.3769 33.0284 3.63573 33.8333 5.01566 33.8333H20.8201L17.7036 48.2642C17.3667 49.8242 18.1318 51.4055 19.5511 52.1217C20.9673 52.8364 22.6926 52.5182 23.7567 51.338L46.6004 26.0048C47.528 24.9761 47.7617 23.4984 47.192 22.2341C46.6231 20.9716 45.3643 20.1667 43.9843 20.1667H28.1799L31.2964 5.73578L29.8302 5.41914L31.2964 5.73577C31.6333 4.17584 30.8682 2.59451 29.4489 1.87826C28.0327 1.16356 26.3074 1.48183 25.2433 2.66198L2.39955 27.9952C1.472 29.0239 1.23831 30.5016 1.80801 31.7659Z"
      stroke="white"
      strokeWidth="3"
      strokeLinejoin="round"
    />
  </svg>
);
export default ElectricityBarWhite;
