import { CustomIcon } from '../types';

const SAEJ1772Connector: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <g clipPath="url(#clip0_29292_5794)">
      <path
        d="M24.0425 17.5717C26.5691 11.4718 23.6725 4.47861 17.5726 1.95195C11.4727 -0.574703 4.4795 2.32197 1.95284 8.42186C-0.573812 14.5217 2.32286 21.5149 8.42275 24.0416C14.5226 26.5682 21.5158 23.6716 24.0425 17.5717Z"
        fill="white"
      />
      <path
        d="M17.676 11.097C18.7823 11.097 19.6791 10.2001 19.6791 9.09383C19.6791 7.98753 18.7823 7.0907 17.676 7.0907C16.5697 7.0907 15.6729 7.98753 15.6729 9.09383C15.6729 10.2001 16.5697 11.097 17.676 11.097Z"
        fill="white"
      />
      <path
        d="M13.1232 21.302C14.2295 21.302 15.1264 20.4052 15.1264 19.2989C15.1264 18.1926 14.2295 17.2958 13.1232 17.2958C12.0169 17.2958 11.1201 18.1926 11.1201 19.2989C11.1201 20.4052 12.0169 21.302 13.1232 21.302Z"
        fill="white"
      />
      <path
        d="M8.32442 11.0559C9.43072 11.0559 10.3276 10.1591 10.3276 9.05281C10.3276 7.94651 9.43072 7.04968 8.32442 7.04968C7.21812 7.04968 6.32129 7.94651 6.32129 9.05281C6.32129 10.1591 7.21812 11.0559 8.32442 11.0559Z"
        fill="white"
      />
      <path
        d="M12.9995 -0.00305176C5.83302 -0.00305176 0.000976562 5.829 0.000976562 12.9955C0.000976562 20.162 5.83302 25.994 12.9995 25.994C20.166 25.994 25.9981 20.162 25.9981 12.9955C25.9981 5.829 20.1689 -0.00305176 12.9995 -0.00305176ZM12.9995 23.9095C6.98141 23.9095 2.08842 19.0136 2.08842 12.9984C2.08842 6.98319 6.98431 2.08439 12.9995 2.08439C19.0147 2.08439 23.9106 6.98029 23.9106 12.9984C23.9106 19.0165 19.0147 23.9095 12.9995 23.9095Z"
        fill="#2B2B2B"
      />
      <path
        d="M17.6777 6.04675C15.9973 6.04675 14.6309 7.41319 14.6309 9.09361C14.6309 10.774 15.9973 12.1405 17.6777 12.1405C19.3581 12.1405 20.7246 10.774 20.7246 9.09361C20.7246 7.41319 19.3581 6.04675 17.6777 6.04675ZM17.6777 10.0559C17.1486 10.0559 16.7183 9.62564 16.7183 9.09651C16.7183 8.56738 17.1486 8.1371 17.6777 8.1371C18.2068 8.1371 18.6371 8.56738 18.6371 9.09651C18.6371 9.62564 18.2068 10.0559 17.6777 10.0559Z"
        fill="#2B2B2B"
      />
      <path
        d="M13.125 16.2517C11.4446 16.2517 10.0781 17.6181 10.0781 19.2986C10.0781 20.979 11.4446 22.3454 13.125 22.3454C14.8054 22.3454 16.1718 20.979 16.1718 19.2986C16.1718 17.6181 14.8054 16.2517 13.125 16.2517ZM13.125 20.2609C12.5958 20.2609 12.1656 19.8306 12.1656 19.3015C12.1656 18.7723 12.5958 18.3421 13.125 18.3421C13.6541 18.3421 14.0844 18.7723 14.0844 19.3015C14.0844 19.8306 13.6541 20.2609 13.125 20.2609Z"
        fill="#2B2B2B"
      />
      <path
        d="M11.371 9.05357C11.371 7.37315 10.0046 6.00671 8.3242 6.00671C6.64378 6.00671 5.27734 7.37315 5.27734 9.05357C5.27734 10.734 6.64378 12.1004 8.3242 12.1004C10.0046 12.1004 11.371 10.734 11.371 9.05357ZM8.3242 10.013C7.79507 10.013 7.36479 9.5827 7.36479 9.05357C7.36479 8.52444 7.79507 8.09416 8.3242 8.09416C8.85332 8.09416 9.28361 8.52444 9.28361 9.05357C9.28361 9.5827 8.85332 10.013 8.3242 10.013Z"
        fill="#2B2B2B"
      />
      <path
        d="M6.87232 14.9753C6.20655 14.9753 5.6687 15.5132 5.6687 16.179C5.6687 16.8447 6.20655 17.3826 6.87232 17.3826C7.5381 17.3826 8.07595 16.8447 8.07595 16.179C8.07595 15.5132 7.5381 14.9753 6.87232 14.9753Z"
        fill="#00FFC2"
      />
      <path
        d="M19.4758 14.9753C18.8101 14.9753 18.2722 15.5132 18.2722 16.179C18.2722 16.8447 18.8101 17.3826 19.4758 17.3826C20.1416 17.3826 20.6795 16.8447 20.6795 16.179C20.6795 15.5132 20.1416 14.9753 19.4758 14.9753Z"
        fill="#00FFC2"
      />
    </g>
    <defs>
      <clipPath id="clip0_29292_5794">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(0 -0.00305176)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SAEJ1772Connector;
