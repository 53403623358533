import { AnalyticsProvider } from '@/components/molecules/AnalyticsProvider';
import { AuthProvider } from '@/components/molecules/AuthProvider';
import { GoogleTagManager } from '@/components/molecules/GoogleTagMananger';
import { FooterTemplate } from '@/components/template/FooterTemplate';
import HeaderTemplate from '@/components/template/HeaderTemplate';
import { PreviousUrlProvider } from '@/context/PreviousUrl';
import { SavedVehiclesProvider } from '@/context/SavedVehicles';
import { VehicleFilterSettingsProvider } from '@/context/VehicleFilterSettings';
import { ZipProvider } from '@/context/ZipContext';
import { AUTH_CONFIG } from '@/lib/constants';
import { BasePageProps } from '@/types/page';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { APIProvider as GoogleMapsAPIProvider } from '@vis.gl/react-google-maps';
import { Amplify } from 'aws-amplify';
import { AwsRumConfig } from 'aws-rum-web';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

Amplify.configure(AUTH_CONFIG, { ssr: true });

type MarketPlaceEntryPointProps = Pick<
  BasePageProps,
  'noHeader' | 'noFooter' | 'user' | 'printHideHeader'
>;

export const MarketPlaceEntryPoint: FC<
  PropsWithChildren<MarketPlaceEntryPointProps>
> = ({ children, noHeader, noFooter, printHideHeader = false, user }) => {
  const toastBackgroundClass = useMemo(
    () => ({
      success: 'bg-green-light/90 border-green-dark',
      error: 'bg-red-light/90 border-red-medium box',
      info: 'bg-brandMidnight/90 border-neutral-200',
    }),
    []
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
  }, []);

  useEffect(() => {
    try {
      const ALLOWED_HOST_NAMES: ReadonlyArray<string> = [
        'ev.com',
        'ev.ca',
        'ev-demo.com',
        'ev-internal.com',
      ];
      if (ALLOWED_HOST_NAMES.includes(window.location.hostname)) {
        const guestRoleArn = process.env.NEXT_PUBLIC_RUM_GUEST_ROLE_ARN;
        const identityPoolId = process.env.NEXT_PUBLIC_RUM_IDENTITY_POOL_ID;
        const applicationId = process.env.NEXT_PUBLIC_RUM_APPLICATION_ID;
        if (guestRoleArn && identityPoolId && applicationId) {
          const config: AwsRumConfig = {
            sessionSampleRate: 1,
            guestRoleArn,
            identityPoolId,
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            telemetries: ['performance', 'errors', 'http'],
            allowCookies: true,
            enableXRay: false,
          };

          const APPLICATION_VERSION = '1.0.0';
          const APPLICATION_REGION = 'us-east-1';

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.rum = new AwsRum(
            applicationId,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
          );
        }
      }
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider ssrUser={user}>
          <PreviousUrlProvider>
            <AnalyticsProvider>
              <SavedVehiclesProvider>
                <ZipProvider>
                  <GoogleMapsAPIProvider
                    apiKey={
                      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_CLIENT || ''
                    }
                    libraries={['places']}
                  >
                    <VehicleFilterSettingsProvider>
                      <div className="flex min-h-screen flex-col">
                        <section className="flex flex-grow flex-col">
                          {!noHeader && (
                            <HeaderTemplate hideOnPrint={printHideHeader} />
                          )}
                          {children}
                          {!noFooter && (
                            <FooterTemplate hideOnPrint={printHideHeader} />
                          )}
                        </section>
                      </div>
                    </VehicleFilterSettingsProvider>
                  </GoogleMapsAPIProvider>
                </ZipProvider>
              </SavedVehiclesProvider>
            </AnalyticsProvider>
          </PreviousUrlProvider>
        </AuthProvider>
      </QueryClientProvider>
      <GoogleTagManager
        gaId={process.env.NEXT_PUBLIC_GTM_ID || ''}
        gAdsId={process.env.NEXT_PUBLIC_GADS_ID || ''}
      />
      <ToastContainer
        toastClassName={(context) => {
          const toastBgClassName =
            context &&
            context?.type &&
            (context.type === 'info' ||
              context.type === 'success' ||
              context.type === 'error')
              ? toastBackgroundClass[context.type]
              : toastBackgroundClass.info;
          return `${toastBgClassName} relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer border-[1px] border-solid boxShadow-m`;
        }}
        bodyClassName={(context) => {
          const fontColor =
            context?.type === 'info' ? 'text-white' : 'text-black';
          return `text-body2Regular px-l py-m ${fontColor}`;
        }}
        closeButton={false}
        position="bottom-center"
        toastStyle={{ alignSelf: 'center', maxWidth: 340 }}
        className="flex flex-col"
        icon={false}
        autoClose={3000}
        hideProgressBar
      />
    </>
  );
};
