import { CustomIcon } from './types';

export const Car: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.41033 4.00053H15.6558C16.2621 4.00645 16.8524 4.196 17.3488 4.54411C17.8411 4.88929 18.2182 5.37437 18.4314 5.93631L19.9267 9.62482C20.0517 9.9331 20.0152 10.2833 19.8294 10.5592C19.6435 10.8351 19.3327 11.0005 19 11.0005H5C4.66819 11.0005 4.35798 10.8359 4.17195 10.5612C3.98592 10.2864 3.94829 9.93727 4.07151 9.62918L5.56847 5.88629C5.79545 5.31863 6.19049 4.83375 6.70062 4.49679C7.20726 4.16213 7.80344 3.98915 8.41033 4.00053Z"
      fill="currentColor"
    />
    <path
      id="Vector (Stroke)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 7.29289C2.68342 6.90237 3.31658 6.90237 3.70711 7.29289L5.70711 9.29289C6.09763 9.68342 6.09763 10.3166 5.70711 10.7071C5.31658 11.0976 4.68342 11.0976 4.29289 10.7071L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289ZM21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L19.7071 10.7071C19.3166 11.0976 18.6834 11.0976 18.2929 10.7071C17.9024 10.3166 17.9024 9.68342 18.2929 9.29289L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289Z"
      fill="currentColor"
    />
    <path
      id="Exclude"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 10.3431 3.34315 9 5 9H19C20.6569 9 22 10.3431 22 12V16C22 17.3062 21.1652 18.4175 20 18.8293V20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20V19H6V20C6 20.5523 5.55228 21 5 21C4.44772 21 4 20.5523 4 20V18.8293C2.83481 18.4175 2 17.3062 2 16V12ZM6 14C6 13.4477 6.44772 13 7 13H7.01C7.56228 13 8.01 13.4477 8.01 14C8.01 14.5523 7.56228 15 7.01 15H7C6.44772 15 6 14.5523 6 14ZM16 14C16 13.4477 16.4477 13 17 13H17.01C17.5623 13 18.01 13.4477 18.01 14C18.01 14.5523 17.5623 15 17.01 15H17C16.4477 15 16 14.5523 16 14Z"
      fill="currentColor"
    />
  </svg>
);
export default Car;
