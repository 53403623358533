import { AUTH_CONFIG } from '@/lib/constants';
import { createServerRunner } from '@aws-amplify/adapter-nextjs';
import {
  fetchAuthSession as fetchAuthSessionServer,
  fetchUserAttributes as fetchUserAttributesServer,
} from 'aws-amplify/auth/server';
import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from 'next';
import { parseCognitoUser } from './parse';
import { RawCognitoUser } from './types';

interface NextApiContext {
  req: NextApiRequest;
  res: NextApiResponse;
}

export const { runWithAmplifyServerContext } = createServerRunner({
  config: AUTH_CONFIG,
});

export const getCognitoUserServerSide = async ({
  req,
  res,
}: GetServerSidePropsContext | NextApiContext) => {
  try {
    const attributes: RawCognitoUser = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: (contextSpec) => fetchUserAttributesServer(contextSpec),
    });

    return parseCognitoUser(attributes);
  } catch (err) {
    return null;
  }
};

export const getUserAuthHeaderServerSide = async ({
  req,
  res,
}: GetServerSidePropsContext | NextApiContext) => {
  try {
    const session = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: (contextSpec) => fetchAuthSessionServer(contextSpec),
    });
    const token = session.tokens?.idToken;

    return token ? `Bearer ${token}` : null;
  } catch (err) {
    return null;
  }
};
