import { MarketPlaceEntryPoint } from '@/components/entry-points/MarketPlaceEntryPoint';
import { SocialSharingTags } from '@/components/molecules/SocialSharingTags';
import '@/styles/globals.css';
import { BasePageProps } from '@/types/page';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { Lexend_Deca } from 'next/font/google';

interface CustomAppProps<T> extends Omit<AppProps, 'pageProps'> {
  pageProps: BasePageProps<T>;
}

const lexendDeca = Lexend_Deca({ subsets: ['latin'] });

function App<T>({
  Component,
  pageProps: {
    noHeader = false,
    noFooter = false,
    printHideHeader = false,
    user,
    metadata,
    ...restPageProps
  },
}: CustomAppProps<T>) {
  return (
    <>
      <SocialSharingTags {...metadata} />
      <style jsx global>{`
        body {
          font-family: ${lexendDeca.style.fontFamily};
        }
      `}</style>
      <MarketPlaceEntryPoint
        noHeader={noHeader}
        noFooter={noFooter}
        user={user}
        printHideHeader={printHideHeader}
      >
        <Component {...restPageProps} />
      </MarketPlaceEntryPoint>
    </>
  );
}

export default appWithTranslation(App);
