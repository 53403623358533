import React, { type JSX } from 'react';

/** @deprecated
 * Just use the HTML heading elements directly
 */
export enum HeadingLevels {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level: HeadingLevels;
}

/** @deprecated
 * Just use the HTML heading elements directly
 */
const Heading: React.FC<HeadingProps> = ({ level, children, ...props }) => {
  const Tag: keyof JSX.IntrinsicElements = level;
  return (
    <Tag {...(props as React.HTMLAttributes<HTMLHeadingElement>)}>
      {children}
    </Tag>
  );
};

export default Heading;
