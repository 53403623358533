import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TIKTOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from '@/lib/constants';
import { FC, useMemo } from 'react';
import { FacebookIcon } from './FacebookIcon';
import { InstagramIcon } from './InstagramIcon';
import { LinkedInIcon } from './LinkedInIcon';
import { TiktokIcon } from './TiktokIcon';
import { TwitterIcon } from './TwitterIcon';
import { YoutubeIcon } from './YoutubeIcon';

interface SocialIconGroupProps {
  /** This component is rendered in more than 1 spot per page,
   * so just using `index` as a key will show React key warnings unless this is passed in. */
  keyPrefix: string;
  iconClassName?: string;
}

const SocialIconGroup: FC<SocialIconGroupProps> = ({
  iconClassName,
  keyPrefix,
}) => {
  const iconGroup = useMemo(() => {
    return [
      {
        href: TIKTOK_URL,
        icon: <TiktokIcon className={iconClassName} />,
        label: 'TikTok',
      },
      {
        href: INSTAGRAM_URL,
        icon: <InstagramIcon className={iconClassName} />,
        label: 'Instagram',
      },
      {
        href: FACEBOOK_URL,
        icon: <FacebookIcon className={iconClassName} />,
        label: 'Facebook',
      },
      {
        href: TWITTER_URL,
        icon: <TwitterIcon className={iconClassName} />,
        label: 'Twitter',
      },
      {
        href: LINKEDIN_URL,
        icon: <LinkedInIcon className={iconClassName} />,
        label: 'LinkedIn',
      },
      {
        href: YOUTUBE_URL,
        icon: <YoutubeIcon className={iconClassName} />,
        label: 'Youtube',
      },
    ].map((footerIcon, index) => (
      <div key={`${keyPrefix}-${index}`}>
        <a
          href={footerIcon.href}
          target="_blank"
          rel="noopener"
          aria-label={footerIcon.label}
        >
          {footerIcon.icon}
        </a>
      </div>
    ));
  }, [iconClassName, keyPrefix]);

  return <>{iconGroup}</>;
};

export default SocialIconGroup;
