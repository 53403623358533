import { CustomIcon } from './types';

const DownPolygon: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
  >
    <path
      d="M4.69928 6.5C4.31438 7.16667 3.35213 7.16667 2.96723 6.5L0.36915 2C-0.0157499 1.33333 0.465375 0.5 1.23518 0.5L6.43133 0.5C7.20113 0.5 7.68225 1.33333 7.29735 2L4.69928 6.5Z"
      fill="#010044"
    />
    <path
      d="M4.69928 6.5C4.31438 7.16667 3.35213 7.16667 2.96723 6.5L0.36915 2C-0.0157499 1.33333 0.465375 0.5 1.23518 0.5L6.43133 0.5C7.20113 0.5 7.68225 1.33333 7.29735 2L4.69928 6.5Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);
export default DownPolygon;
