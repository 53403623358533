import Wrapper from '@/components/atoms/legacy/Wrapper';
import NavBar from '@/components/organisms/NavBar';
import React from 'react';

const HeaderTemplate: React.FC<{
  hideOnPrint: boolean;
}> = ({ hideOnPrint }) => {
  return (
    <Wrapper
      className={`relative border-b-[1px] ${hideOnPrint ? 'print:hidden' : ''}`}
    >
      <NavBar id="main-nav" className="col-span-full" />
    </Wrapper>
  );
};

export default HeaderTemplate;
