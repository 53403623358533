import { CustomIcon } from '../types';

const BatteryChargerHigh: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M13.0013 21.667H4.33464V6.50033H13.0013M13.7271 4.33366H11.918V2.16699H5.41797V4.33366H3.6088C2.81797 4.33366 2.16797 4.98366 2.16797 5.77449V22.3928C2.16797 23.1837 2.81797 23.8337 3.6088 23.8337H13.7271C14.5288 23.8337 15.168 23.1945 15.168 22.3928V5.77449C15.168 4.98366 14.518 4.33366 13.7271 4.33366ZM11.918 17.3337H5.41797V20.5837H11.918V17.3337ZM11.918 7.58366H5.41797V10.8337H11.918V7.58366ZM11.918 12.4587H5.41797V15.7087H11.918V12.4587ZM24.918 10.8337H21.668V3.25033L16.2513 14.0837H19.5013V22.7503"
      fill="#38393A"
    />
    <path
      d="M5.28125 20.7188V17.0625H12.1875V20.7188H5.28125Z"
      fill="#0F855A"
    />
    <path
      d="M5.28125 15.8438V12.1875H12.1875V15.8438H5.28125Z"
      fill="#0F855A"
    />
    <path d="M5.28125 10.9688V7.3125H12.1875V10.9688H5.28125Z" fill="#0F855A" />
  </svg>
);
export default BatteryChargerHigh;
