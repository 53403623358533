import { initialSavedVehicles } from '@/context/SavedVehicles';
import { GetServerSidePropsContext } from 'next';
import { getUserAuthHeaderServerSide } from './schema/user/server-fetch';
import { SavedVehicles, StoreField } from './schema/user/types';

export const getInitials = (fullName?: string): string =>
  fullName
    ? fullName
        .split(' ')
        .map((name) => name[0].toUpperCase())
        .join('')
    : '';

const getUserStore = async (
  context: GetServerSidePropsContext,
  storeField?: StoreField
): Promise<SavedVehicles | null> => {
  const Authorization = await getUserAuthHeaderServerSide(context);
  if (Authorization) {
    const response = await fetch(
      `${process.env.API_ENDPOINT}/api/user/user_store${storeField ? `?store_field=${storeField}` : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization,
        },
      }
    );
    if (response.ok) {
      const result: SavedVehicles = await response.json();
      return result;
    }
    return initialSavedVehicles;
  }
  return null;
};

const isVehicleSavedOnServer = async ({
  context,
  storeField,
  id,
}: {
  context: GetServerSidePropsContext;
  storeField: StoreField;
  id: string | number;
}): Promise<boolean> => {
  let isSaved = false;
  const savedVehicles = await getUserStore(context);
  isSaved = !!savedVehicles?.[storeField]?.[id];
  return isSaved;
};

export { getUserStore, isVehicleSavedOnServer };
