import { CustomIcon } from '../types';

const ElectricityBarHybridWhite: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="49"
    height="54"
    viewBox="0 0 49 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_24105_970)">
      <path d="M0 0H28L21 54H0V0Z" fill="white" />
      <mask
        id="path-2-outside-1_24105_970"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="49"
        height="54"
        fill="black"
      >
        <rect fill="white" width="49" height="54" />
        <path d="M29.8302 5.41914C30.0218 4.53228 29.5879 3.62856 28.7731 3.2174C27.9584 2.80623 26.9667 2.99057 26.3572 3.66649L3.51353 28.9997C2.98348 29.5876 2.85112 30.4296 3.17558 31.1497C3.50005 31.8697 4.22073 32.3333 5.01566 32.3333H22.6786L19.1698 48.5809C18.9782 49.4677 19.4121 50.3714 20.2269 50.7826C21.0416 51.1938 22.0333 51.0094 22.6428 50.3335L45.4865 25.0003C46.0165 24.4124 46.1489 23.5704 45.8244 22.8503C45.5 22.1303 44.7793 21.6667 43.9843 21.6667H26.3214L29.8302 5.41914Z" />
      </mask>
      <path
        d="M29.8302 5.41914C30.0218 4.53228 29.5879 3.62856 28.7731 3.2174C27.9584 2.80623 26.9667 2.99057 26.3572 3.66649L3.51353 28.9997C2.98348 29.5876 2.85112 30.4296 3.17558 31.1497C3.50005 31.8697 4.22073 32.3333 5.01566 32.3333H22.6786L19.1698 48.5809C18.9782 49.4677 19.4121 50.3714 20.2269 50.7826C21.0416 51.1938 22.0333 51.0094 22.6428 50.3335L45.4865 25.0003C46.0165 24.4124 46.1489 23.5704 45.8244 22.8503C45.5 22.1303 44.7793 21.6667 43.9843 21.6667H26.3214L29.8302 5.41914Z"
        fill="#62A1FF"
      />
      <path
        d="M29.8302 5.41914C30.0218 4.53228 29.5879 3.62856 28.7731 3.2174C27.9584 2.80623 26.9667 2.99057 26.3572 3.66649L3.51353 28.9997C2.98348 29.5876 2.85112 30.4296 3.17558 31.1497C3.50005 31.8697 4.22073 32.3333 5.01566 32.3333H22.6786L19.1698 48.5809C18.9782 49.4677 19.4121 50.3714 20.2269 50.7826C21.0416 51.1938 22.0333 51.0094 22.6428 50.3335L45.4865 25.0003C46.0165 24.4124 46.1489 23.5704 45.8244 22.8503C45.5 22.1303 44.7793 21.6667 43.9843 21.6667H26.3214L29.8302 5.41914Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M29.8302 5.41914C30.0218 4.53228 29.5879 3.62856 28.7731 3.2174C27.9584 2.80623 26.9667 2.99057 26.3572 3.66649L3.51353 28.9997C2.98348 29.5876 2.85112 30.4296 3.17558 31.1497C3.50005 31.8697 4.22073 32.3333 5.01566 32.3333H22.6786L19.1698 48.5809C18.9782 49.4677 19.4121 50.3714 20.2269 50.7826C21.0416 51.1938 22.0333 51.0094 22.6428 50.3335L45.4865 25.0003C46.0165 24.4124 46.1489 23.5704 45.8244 22.8503C45.5 22.1303 44.7793 21.6667 43.9843 21.6667H26.3214L29.8302 5.41914Z"
        stroke="white"
        strokeWidth="6"
        strokeLinejoin="round"
        mask="url(#path-2-outside-1_24105_970)"
      />
    </g>
    <defs>
      <clipPath id="clip0_24105_970">
        <rect width="49" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ElectricityBarHybridWhite;
